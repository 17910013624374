.comment-page {
    background-color: var(--secondary-colour);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    min-height: 609px;

}

.comment-page > div {
    justify-content: start;
    align-items: center;

}

.comment-page .display-5 {
    /*margin-top: 20%;*/
    margin-bottom: 0.6em;

}
.comment-container {
    background-color: var(--secondary-colour);
    /*color: var(--secondary-text-colour);

    text-shadow: -1px 1px 0 var(--primary-text-colour), 
                          1px 1px 0 var(--primary-text-colour), 
                         1px -1px 0 var(--primary-text-colour), 
                        -1px -1px 0 var(--primary-text-colour);*/
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-items: space-between;
    height: 100%;
    flex: 0 0 35% !important;
    max-width: 35%;
}

.comment-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 30% !important;
    max-height: 30%;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
}


.comment-container form {
    background-color: var(--primary-colour);
    box-shadow: 3px 3px rgb(0, 0, 0, 0.35);
    padding: 2em;
    border-radius: 3px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
}
.comment-container .form-container {
    flex: 0 0 65% !important;
    max-height: 65%;

}

.comment-display ul {

    flex: 0 0 65% !important;
    max-height: 65%;
    z-index: 0;
    min-width: 247px;
}

.comment-display {
    background-color: var(--primary-colour);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 0 0 60% !important;
    max-width: 60%;
    z-index: 0;
    min-width: 247px;
    animation: slide-down-entrance 1s -0.5s;
    box-shadow: -3px 0px rgb(0, 0, 0, 0.35), 3px 0px rgb(0, 0, 0, 0.35);
}

.comment-display li {
    background-color: rgba(0,0,0,0);

}

.comment-right-pane {
    background-color: var(--secondary-colour);
    /*color: var(--secondary-text-colour);

    text-shadow: -1px 1px 0 var(--primary-text-colour), 
                          1px 1px 0 var(--primary-text-colour), 
                         1px -1px 0 var(--primary-text-colour), 
                        -1px -1px 0 var(--primary-text-colour);*/
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    flex: 0 0 5% !important;
    max-width: 5%;

}


@media screen and (max-width: 1024px) /*and (min-width: 420px)*/ {

    .comment-right-pane {
        flex: 0 0 0% !important;
        max-width: 0%;

    }
    .comment-container {
        flex: 0 0 47% !important;
        max-width: 47%;
    }

    .comment-display {
        flex: 0 0 53% !important;
        max-width: 53%;
    }
}
@media screen and (max-width: 720px) /*and (min-width: 420px)*/ {
    .comment-page {
        height: fit-content!important;
    }
    .comment-container {
        padding: 1em;
        flex: 0 0 100% !important;
        max-width: 100%;
    }
    .comment-display {
        padding: 1em;
        flex: 0 0 100% !important;
        box-shadow: 0px -3px rgb(0, 0, 0, 0.35);
        max-width: 100%;
    }
    .comment-title-container {
        margin-top: 2em;
        margin-bottom: 1em;
        flex: 0 0 10% !important;
        max-height: 10%;
    }
}
@media screen and (max-width: 414px) /*and (min-width: 420px)*/ {
}

/* Animations */

.comment-container > * {

    animation: slide-up-entrance 1s -0.5s;
}

.fade-out .comment-container > *{
    animation: slide-up-exit 0.8s;
}
.fade-out .comment-display {
    animation: slide-down-exit 0.8s;
}

@media screen and (max-width: 414px) /*and (min-width: 420px)*/ {


    .comment-display {
        animation: slide-right-entrance 1s -0.5s;
    }
    .fade-out .comment-display {
        animation: slide-right-exit 0.8s;
    }
    .comment-container > * {

        animation: slide-left-entrance 1s -0.5s;
    }
    .fade-out .comment-container > *{
        animation: slide-left-exit 0.8s;
    }
}