@import '../App.css';

.nav-bar-container {
	width: 100%;
	height: fit-content;
	margin: 0vh 0px !important;
	padding: 0px !important;
	justify-content: center;
	position: fixed;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	right: 0px;
	/*align-items: end;*/
	z-index: 3;
	/*border-bottom: 1px solid rgba(0, 36, 61, 0.1);*/
}

.nav-bar-container input {
	width: 100%;
	height: 100%;
	opacity: 0;
	/*display: none;*/
	z-index: 3;
}

.nav-bar {
	border-radius: 45px;
	margin-top: 10px;
	height:	fit-content;
	min-width: 217px;
	justify-content: space-around;
	align-items: center;
	position: fixed;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
    animation: slide-down-entrance 1s 0.4s;
    animation-fill-mode: backwards;
	background-color: var(--secondary-text-colour-transparent-2);
	/*right: 3px;*/
}


.nav-bar:hover {
}

.nav-button {
	width: 5em;
	font-size: calc(1em);
	background-color: rgba(0,0,0,0);
	color: #00243d;
	border: None;
	margin: 0 0.3vw;
	padding: 0px;
	opacity: 0.3;
	transition: font-size 0.1s, opacity 0.1s;
}

.nav-button:hover {
	opacity: 0.8;
	font-size: calc(1.5em);
}

.nav-button-selected {
	font-size: calc(2em) !important;
	opacity: 0.8;
}

@media screen and (max-width: 767px) {
	.nav-bar-container input {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: 3;
	}
	.nav-bar-container {
		min-width: 242px;
		margin: 0 !important;
		height: 8vh;
		min-height: calc(3em + 10px);
		z-index: 3;
		justify-content: end;
		align-content: end;
	    align-items: center;
		display: flex;
		transition: background-color 0.1s 0.1s ease-in,, box-shadow 0.1s ease-in 0.1s, background-image 0.3s 0.1s ease-in;
	}

	.hamburger-menu {
		-moz-visibility: hidden;
		position: absolute;
		right: 5px;
		z-index: 3;
		background-size: cover;
		width: 42px;
		height: 25px;
		min-height: 3em;
		/*animation: select-momentary-shrink 0.1s;*/
		/*background-image: url('../images/burger-menu.svg');*/
	}
	.hamburger-menu:hover {
		cursor: pointer;

	}

	.hamburger-menu > div {
		-moz-visibility: hidden;
		position: absolute;
		height: fit-content;
		width: fit-content;
		display: flex;
		flex-wrap: no-wrap;
		flex-direction: column;
		justify-content: space-evenly;
		padding: 5px;
		background-color: var(--secondary-text-colour-transparent-2);
		border-radius: 5px;
		width: 100%;
		height: 100%;
	}

	.hamburger-menu span {
		position: relative;
		display: flex;
		z-index: 2;
		opacity: 0.8;
		background-size: cover;
		width: 32px !important;
		height: 0.25em;
		margin: 0em 0;
		transition: transform 0.2s;
		background-color: var(--primary-text-colour);
	}

	.nav-bar-container .nav-bar {
		transition: opacity 0.1s 0.1s ease-in;
		align-items: end;
		opacity: 0;
		z-index: 3;
		visibility: hidden;
		width: fit-content;
	}
	.nav-bar-container:has(input:checked)  {
		transition: background-color 0.3s ease-in 0s, box-shadow 0.3s ease-in 0s, background-image 0s ease-in 0s/*, width 0.1s ease-in 0s*/ ;
		width: 100%;
		margin: 0 !important;
		justify-content: center;
		justify-items: center;
		align-content: end;
		position: fixed;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		z-index: 3;
		/*background-image: none;*/
		box-shadow: 0px 2px rgb(0, 0, 0, 0.35);
		background-color: var(--primary-colour-transparent);
		background-image: url('../images/burger-menu.svg');
	}
	.nav-bar-container .hamburger-menu:has(input:checked) ~ .nav-bar {
		justify-items: center;
		align-items: center;
		align-content:  center;
		z-index: 3 !important;
		opacity: 1 !important;
		visibility: visible !important;
		background-color: rgba(0,0,0,0);
		margin-top: 0px;
		height: 8vh;
	}

	.hamburger-menu:has(input:checked) > div {
		background-color: rgba(0,0,0,0);
		animation: select-momentary-shrink 0.1s;
		/*border-radius: 100%;*/
		/*transform: scale(0.65);*/
		outline: 3px solid var(--secondary-text-colour-transparent-2);
	}
	.hamburger-menu input:checked ~ div span:nth-child(2) {
		opacity: 0;
	}
	.hamburger-menu input:checked ~ div span:nth-child(1) {
		transform-origin: 0;
		transform: translate(5px, 0px) rotate(45deg) translate(-1px, 0px);
	}
	.hamburger-menu input:checked ~ div span:nth-child(3) {
		transform-origin: 0;
		transform: translate(5px, 0px) rotinate(-45deg) translate(-1px, 0px);
	}
	.nav-button {
		font-size: calc(1vw + 0.6em);
	}

	.nav-button:hover {
		font-size: calc(1vw + 0.8em);
	}

	.nav-button-selected {
		font-size: calc(1vw + 1em) !important;
	}

	@supports not (selector(html:has(body))) {
		.nav-bar-container {
			transition: background-color 0.3s ease-in 0s, box-shadow 0.3s ease-in 0s, background-image 0s ease-in 0s/*, width 0.1s ease-in 0s*/ ;
			width: 100%;
			margin: 0 !important;
			justify-content: center;
			justify-items: center;
			align-content: end;
			position: fixed;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			min-height: 2em;
			height: 6vh;
			z-index: 3;
			/*background-image: none;*/
			box-shadow: 0px 2px rgb(0, 0, 0, 0.35);
			background-color: var(--primary-colour-transparent);
			background-image: url('../images/burger-menu.svg');
		}
		.nav-bar {
			justify-items: center;
			align-items: center;
			align-content:  center;
			z-index: 3 !important;
			opacity: 1 !important;
			visibility: visible !important;
			background-color: rgba(0,0,0,0);
			margin-top: 0px;
			height: 6vh;

		}
		.hamburger-menu {
			visibility: hidden;
		}
	}

}

@media screen and (max-width: 420px) {
}
