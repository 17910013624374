.contact-page {
    background-color: var(--secondary-colour);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    min-height: 611px;

}

.form-container {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 68%;
    max-height: 68%;
    max-height: fit-content;

}

.form-container form {
    height: fit-content;
    background-color: var(--primary-colour);
    box-shadow: 3px 3px rgb(0, 0, 0, 0.35);
    padding: 2em;
    border-radius: 3px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
}

.form-container form textarea {
    max-height:  115px;
}

.contact-page > div {
    justify-content: space-around;
    /*justify-items: space-around;*/
    align-items: center;
}

.contact-page .display-5 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 0 0 16%;
    max-height: 16%;
    /*margin-top: 1.5em;*/
    margin-bottom: 0.3em;

}
.contact-container {
    background-color: var(--secondary-colour);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0em 1em 0em 1em;
    height: fit-content;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    /*min-height: 611px;*/
    flex: 0 0 60% !important;
    max-width: 60%;
    height: 100%;
    min-height: 661px;
    z-index: 0;
    
}

.contact-container > * {

    animation: slide-up-entrance 0.6s 0s;
}

.contact-side-left {
    
    animation: slide-right-entrance 1s -0.4s;
}

.fade-out .contact-container > * {

    animation: slide-down-exit 0.8s;
}

.fade-out .contact-side-left {
    
    animation: slide-left-exit 0.8s;
}

.contact-side-right {
    
    animation: slide-left-entrance 1s -0.4s;
}

.fade-out .contact-side-right {
    
    animation: slide-right-exit 0.8s;
}


.contact-side {
    background-color: var(--primary-colour);
    flex: 0 0 20% !important;
    max-width: 20%;
    height: 100%;
    z-index: 1;
    box-shadow: -3px 0px rgb(0, 0, 0, 0.35), 3px 0px rgb(0, 0, 0, 0.35);
}


@media screen and (max-width: 1024px) /*and (min-width: 420px)*/ {
    .contact-container {
        flex: 0 0 100% !important;
        max-width: 100%;

        box-shadow: 0px 0px rgb(0, 0, 0, 0.35), 0px 0px rgb(0, 0, 0, 0.35);
    }

    .contact-side {
        display: hidden;

    }

}