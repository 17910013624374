:root {
	--primary-colour: #9AE3B2;
	--primary-colour-transparent: rgba(154, 227, 178, 0.8);
	--secondary-colour: #04b5a3;
	--primary-colour-light: #BFEDCE;
	--primary-colour-light-transparent:  rgba(191, 237, 206, 0.8);
	--primary-text-colour: #00243d;
	--primary-text-colour-transparent: rgba(0, 36, 61, 0.8);
	--secondary-text-colour: #EFFBF3;
	--secondary-text-colour-transparent: rgb(239, 251, 243, 0.8);
	--secondary-text-colour-transparent-2: rgb(239, 251, 243, 0.2);
}

@font-face {
  font-family: Poppins;
  src: url(./Fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: Titillium-black;
  src: url(./Fonts/titillium-web/TitilliumWeb-Black.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;;
}

@font-face {
  font-family: Titillium-bold;
  src: url(./Fonts/titillium-web/TitilliumWeb-Bold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;;
}

@font-face {
  font-family: Konkretika;
  src: url(./Fonts/Konkretika-Black-WIP.ttf);
}

main {
	width: 100%;
	font-family: "Titillium-bold", sans-serif;
}

a:hover {
	cursor:pointer;
}

.floating {  
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
 
@keyframes wiggle {
    10%  { transform: rotate(1deg);}
    20%   { transform: rotate(-1deg);}    
    30%  { transform: rotate(2deg);}
    40%   { transform: rotate(-2deg);}    
    50%  { transform: rotate(1deg);}
    60%   { transform: rotate(-3deg);}    
    70%   { transform: rotate(3deg);} 
    80%  { transform: rotate(1deg);}
    90%   { transform: rotate(-1deg);}       
    100%   { transform: rotate(0deg);}    
}
 
@keyframes wiggle-sm {
    10%  { transform: rotate(1deg);}
    20%   { transform: rotate(-1deg);}    
    30%  { transform: rotate(2deg);}
    40%   { transform: rotate(-2deg);}    
    50%  { transform: rotate(1deg);}
    60%   { transform: rotate(-1deg);}    
    70%   { transform: rotate(2deg);} 
    80%  { transform: rotate(-2deg);}
    90%   { transform: rotate(1deg);}       
    100%   { transform: rotate(0deg);}    
}
 
@keyframes wiggle-tn {
    20%  { transform: rotate(0.5deg);}
    40%   { transform: rotate(-0.5deg);} 
    60%  { transform: rotate(1deg);}
    80%   { transform: rotate(-1deg);}       
    100%   { transform: rotate(0deg);}    
}
 
@keyframes floating {
    0% { transform: translate(0,  0px) }
    25%  { transform: translate(0, 4px) }
    50%   { transform: translate(0, 2px) }    
    75%   { transform: translate(0, 4px)}    
    100%   { transform: translate(0, 0px)}    
}
 
@keyframes subtle-rotation {
    0% { transform: rotate(0deg);}
    25%  { transform: rotate(1deg);}
    50%   { transform: rotate(0deg);}    
    75%   { transform: rotate(-1deg);}    
    100%   { transform: rotate(0deg);}    
}
@keyframes subtle-rotation-left {
    0% { transform: rotate(-15deg);}
    25%  { transform: rotate(-14deg);}
    50%   { transform: rotate(-15deg);}    
    75%   { transform: rotate(-16deg);}    
    100%   { transform: rotate(-15deg);}    
}
@keyframes subtle-rotation-right {
    0% { transform: rotate(15deg);}
    25%  { transform: rotate(14deg);}
    50%   { transform: rotate(15deg);}    
    75%   { transform: rotate(16deg);}    
    100%   { transform: rotate(15deg);}    
}

@keyframes slide-down-entrance {
    0%  {
    	transform: translate(0,  -100%);
    }      
    100%   {
    	transform: translate(0,  0%);
    }    
}

@keyframes slide-up-entrance {
    0%  {
    	transform: translate(0,  100%);
    }      
    100%   {
    	transform: translate(0,  0%);
    }    
}
@keyframes slide-right-entrance {
    0%  {
    	transform: translate(-100%,  0);
    }      
    100%   {
    	transform: translate(0,  0%);
    }    
}
@keyframes slide-left-entrance {
    0%  {
    	transform: translate(100%,  0);
    }      
    100%   {
    	transform: translate(0,  0%);
    }    
}
@keyframes slide-up-exit {
    0%  {
    	transform: translate(0,  0%);
    }      
    100%   {
    	transform: translate(0,  -100%);
    }    
}

@keyframes slide-down-exit {
    0%  {
    	transform: translate(0,  0%);
    }      
    100%   {
    	transform: translate(0,  100%);
    }    
}
@keyframes slide-left-exit {
    0%  {
    	transform: translate(0,  0%);
    }      
    100%   {
    	transform: translate(-100%,  0);
    }    
}
@keyframes slide-right-exit {
    0%  {
    	transform: translate(0,  0%);
    }      
    100%   {
    	transform: translate(100%,  0);
    }    
}

@keyframes select-momentary-shrink {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(0.9);
	}
	50% {
		transform: scale(0.85);
	}
	80% {
		transform: scale(0.9);
	}
	100% {
		transform: scale(1);
	}
}

.bg-transparent-25 {
	background-color: rgba(255,255,255,.5) !important;
}

.bg-transparent-60 {
	background-color: rgba(255,255,255,.65) !important;
}

.bg-transparent-0 {
	background-color: rgba(255,255,255,0) !important;
}

.bottom-border {
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.left-border {
	border-left: 1px solid rgba(0, 0, 0, 1);
}

.vw-20 {
	min-width: 20vw !important;
}

.form-control {
	border-color: #747578 !important;
}

.text-rich-black {
	color: var(--primary-text-colour)
}

.footer {
	width: 100%;
	height: 4em;
	margin: 0px;
	margin-top: 3em;
	padding: 0px;
	background-color: rgba(0, 0, 0, 0.2);
	margin-bottom: 0px;
}

.img-rounded {
	/*border-radius: 6em;*/
}

.height-80vh {
	height: 80vh;

}

.height-70vh {
	height: 70vh;

}

.height-100p {
	height: 80vh;

}

.fill-remainder {
	height:calc(100% - 48px);
	align-content: center;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}


.width-80vw {
	height: 80vh;

}

.top-20p {
	top: 20%;
}

.top-15p {
	top: 15%;
}

.top-35p {
	top: 35%;
}

.left-80p {
	left: 80%;
}

.left-85p {
	left: 85%;
}

.app-container {
	justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

.ben-container {
	margin-right: auto;
	margin-left: auto;
	height: fit-content;
	/*max-width: 1600px;*/
	width: 100vw;
	border-radius: 1vh;
	min-height: 100vh;
	margin: 0vh 0vw;
	border: 3px solid var(--primary-text-colour-transparent);
	/*border-left: 0px;*/
	position: relative;
	overflow: hidden;

  background-color: var(--primary-colour);
}

.display-5 {
	font-family: "Titillium-bold", sans-serif;
  	font-size: 2.9rem;
    font-weight: 280;
    line-height: 1.1;
}

.swipe-icon {
	background-color: var(--secondary-text-colour-transparent);
	border-radius: 75px;
	position: fixed;
	top: -100px;
	z-index: 3;
	opacity: 0;
	transition: opacity 0.2s;
	pointer-events: none
}

@media screen and (max-width: 1024px) and (min-width: 420px) {

	.height-80vh {
		height: inherit;
	}
}

@media screen and (max-width: 420px) {
	.swipe-icon {
		top: 20%;
		pointer-events: auto
	}
	.height-80vh {
		height: inherit;
	}
	.ben-container {
		/*margin: 3em 0vw 0vh 0vw;*/
	}

	.display-5 {
		font-size: 10vw;
	    padding-left: 0 !important;
	}

	.left-border {
		border-left: 0px solid rgba(0, 0, 0, 1);
	}
}