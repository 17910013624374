@import '../App.css';

.about-page {
	background-color: var(--secondary-colour);
	flex-direction: row;
	display: flex;
	height: 100vh;
}

.about-buttons-container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	flex: 0 0 10% !important;
	max-width: 10%;
	animation: slide-right-entrance 1s -0.4s;

}

.about-button-cover {
	height: 100%;
	width: 100%;
	background-color: var(--primary-colour-light-transparent);
	opacity: 0.9;
	transition: opacity 70ms ease-in-out;
	border: 0.1px solid grey;
	text-align: center;
}


.about-button-cover:hover {
	opacity: 0.0;
}

.about-image-container {
	background-color: var(--secondary-colour);
	margin: 0px;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: 100%;
	width: 100%;
	flex: 0 0 40% !important;
	max-width: 40%;
	animation: slide-left-entrance 1s -0.4s;

}


.about-image-anchor {
	display: flex;
	width: 100%;
	height: 100%;
	flex: 0 0 33.3333%;
	max-width: 100%;

}


.about-description-container {
	background-color: var(--primary-colour);
	z-index: 1;
	align-content: center;
	justify-content: center;
	margin: 0px;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	height: auto;
	width: 100%;
	flex: 0 0 50% !important;
	max-width: 50%;
	box-shadow: 3px 0px rgb(0, 0, 0, 0.35), -3px 0px rgb(0, 0, 0, 0.35);
	animation: slide-down-entrance 1s -0.4s;
}

.about-description-widget {
	margin: 0px 3vw;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.panel:active {
	animation: select-momentary-shrink 0.1s
}

.hover-highlight:hover {
	cursor: pointer;
	animation: highlight 0.15s linear;
	opacity: 1;
	transition: transform 0.2s;
	transform: scale(1.05);
	background-color: rgba(255, 255, 255, 0.3);

}

.hover-highlight:hover svg{
	animation: highlight-arrow 0.12s linear;
	opacity: 0.6;
	transition: transform 0.2s;
	transform: scale(1.1);
}

.dots-container {
	width: 100%;
	margin: 1em;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	height: fit-content;
}

.dots-container a {
	display: inline-block;
}

.dots-container circle {
	transform-origin: center;
	-webkit-transform-origin: center;
	transition: transform 0.3s cubic-bezier(0.1,0.1,0.3, 0.8);
	-webkit-transition: transform 0.3s cubic-bezier(0.1,0.1,0.3, 0.8);

}
.dots-container svg {
	margin: 0 .4em;

}

.dots-container svg:hover {
	z-index: 2;
}

.dots-container a:hover circle:not(.about-dot-selected){
	transition: transform 0.15s cubic-bezier(0.1,0.1,0.3, 0.8);
	-webkit-transition: transform 0.15s cubic-bezier(0.1,0.1,0.3, 0.8);
	transform: scale(1.4);
	-webkit-transform: scale(1.4);
}

.about-dot-selected {
	transform-origin: center;
	-webkit-transform-origin: center;
	transform: scale(2.5);
	-webkit-transform: scale(2.5);
	transition: transform 1s cubic-bezier(0.1,0.1,0.5, 0.7);
	-webkit-transition: transform 1s cubic-bezier(0.1,0.1,0.5, 0.7);
}

.left-panel {
	min-width: 10%;
}

.right-panel {
	min-width: 10%;
}

.text-bubble {
	min-width: 80%;
}
/*
.text-bubble::after {
    display: block;
    content: '';
    width: 100%;
    height: 20px;
}*/

.ben-binoculors-image {
	/*background-color: rgba(255,255,255,.3) !important;*/
	background-image: url('../images/ben-binoculors.jpg');

}
.ben-cartoon-computer-image {
	/*background-color: rgba(255,255,255,.3) !important;*/
	background-position: center !important;
	background-image: url('../images/ben-cartoon-computer.jpg');

}
.ben-mind-image {
	/*background-color: rgba(255,255,255,.3) !important;*/
	background-position: center !important;
	background-image: url('../images/ben-mind.jpg');

}
.about-image {
	/*background-color: rgba(255,255,255,.3) !important;*/
	background-size: cover;
	background-position: center;
	display: flex;
	width: 100%;
	height: 100%;
	flex: 0 0 100%;
	max-height: 100%;
	content:url('../images/ben-binoculors.jpg') url('../images/ben-cartoon-computer.jpg') url('../images/ben-mind.jpg');
	animation: slide-left-entrance 1s -0.4s, fade-in 0.3s ease-in-out 0.15s;
  	animation-fill-mode: forwards;

}

/*.ben-button-container {
	display: flex;
	flex-direction: column;
	flex: 0 0 30%;
	max-height: 30%;

}*/

.linked-in-button {
	background-size: cover;
	background-position: center;
	display: flex;
	width: 100%;
	height: 100%;
	background-color: white;
	background-image: url('../images/linkedin.svg');
}

.github-button {
	background-size: cover;
	background-position: center;
	display: flex;
	width: 100%;
	height: 100%;
	background-color: white;
	background-image: url('../images/github.svg');
}

.resume-button{
	background-size: cover;
	background-position: center;
	display: flex;
	width: 100%;
	height: 100%;
	background-color: white;
	background-image: url('../images/resume.svg');
}
.text-bubble {
	min-height: 17em;
	font-size: 1.25rem;
}


@media screen and (max-width: 1024px) /*and (min-width: 420px)*/ {


	.about-page {
		flex-direction: row;
		max-height: none;
	}
	.about-description-container {
		align-items: center;
		flex: 0 0 50% !important;
		max-width: 50%;
	}

	.about-description-widget {
		flex-direction: row;
		flex-wrap: wrap;
		align-content: center;
		height: 65%;
	}

	.about-image-container {
		flex: 0 0 50% !important;
		max-width: 50%;
	}

	.ben-image {
		background-position: 75% 50%;
	}
	.text-bubble {
		flex: 0 0 100% !important;
		max-width: 100%;
		min-height: 87%;
		padding-bottom: 1em;
		font-size: calc(0.7em + .6vw) !important;
	}
	.dots-container {
		/*flex: 0 0 20% !important;*/
		/*min-height: 20%;*/
		min-width: 111px;
	}
	.left-panel {
		flex: 0 0 50% !important;
		max-width: 50%;
		height: fit-content;
	}
	.right-panel {
		flex: 0 0 50% !important;
		max-width: 50%;
		height: fit-content;
	}
}
@media screen and (max-width: 720px) /*and (min-width: 420px)*/ {


	.about-page {
		flex-direction: column-reverse;
		height: fit-content;
	}
	.about-image-container {
		flex: 0 0 100% !important;
		max-width: 100%;
	}

	.about-description-container {
		padding: 2em 0em;
		flex: 0 0 100% !important;
		max-width: 100%;
		box-shadow: 0px -3px rgb(0, 0, 0, 0.35), 0px 3px rgb(0, 0, 0, 0.35);
	}

	.about-image {
		width: 100%;
		padding-top: 60%;
	}

	.about-buttons-container {
		height: 200px !important;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: center;
		flex: 0 0 15% !important;
		max-width: 100%;
	}

	.about-buttons-container > * {
		background-size: cover;
		height: 100px !important;
		flex: 0 0 100% !important;
		max-width: 33.333%;

	}

	.about-button-cover {
		background-color: none;
		opacity: 0.5;
	}

	.text-bubble {
		text-align: center;
		min-height: 60%;
	}
	.left-panel {
		flex: 0 0 50% !important;
		max-width: 50%;
	}
	.right-panel {
		flex: 0 0 50% !important;
		max-width: 50%;
	}
}
@media screen and (max-width: 414px) /*and (min-width: 420px)*/ {
	.about-image {
		width: 100%;
		padding-top: 100%;
	}
}


/* Animated exits */

.fade-out .about-image-container {
	animation: slide-right-exit 0.8s;
}
.fade-out .about-description-container {
	animation: slide-up-exit 0.8s;
}
.fade-out .about-buttons-container {
	animation: slide-left-exit 0.8s;
}