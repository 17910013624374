@import '../App.css';


.comment-display {
	/*border-left: 1px solid rgba(0, 0, 0, 1);*/
}

.comment-display > div {
	margin-left: 3px;
	margin-bottom: 4px;

}

.icons-container {
	display: flex;
	justify-content: center;

}

.icons-container > div {
	padding: 3vh;
}

.icons-container div:nth-child(2) {
	padding-top:  6vw;
}

.linkedin-background {
	background-image: url('../images/linkedinlogo.png');
  animation-name: subtle-rotation-right;
}

.github-background {
	background-image: url('../images/githublogo.png');
  animation-name: subtle-rotation-left;
}

.icon-background {
	background-size: 64px;
	background-position: right;
	box-shadow: -3px 7px rgb(0, 0, 0, 0.35);
	width: 64px;
	height: 64px;
	border-radius: 32px;
	filter: grayscale(100%);

  animation-duration: 8s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-fill-mode: forwards;

  transition: filter 0.3s;
}

.icon-background:hover {
	filter: grayscale(0%) opacity(70%);

}

.ben-cartoon {
	/*background-color: rgba(255,255,255,.3) !important;*/
	background-image: url('../images/BenCartoon.jpeg');
	background-size: cover;
	background-position: center;
	box-shadow: -3px 7px rgb(0, 0, 0, 0.35);
	width: 100%;
	height: 100%;
	margin-right: 3vw;
	margin-top: 0vw;
	border: 3px solid var(--primary-text-colour);
	
  animation-name: subtle-rotation;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
	/*border-radius: 2em;*/

}

.ben-background-container {
	width: 12em;
	height: 12em;

	position: relative;

}

.floater {
  animation-name: floating;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

}

.wiggler {

}

.wiggler:hover {
  animation-name: wiggle;
  animation-iteration-count: infinite;
  animation-duration: 0.7s;
}

.wiggler-sm-rumble {

}

.wiggler-sm-rumble:hover {
  animation-name: wiggle-sm, wiggle-tn;
  animation-iteration-count: 1, infinite;
  animation-duration: 0.7s, 0.3s;
  animation-delay: 0s, 0.7s;
}

.text-bubble {
	/*background-color: #BCFDD1 !important;*/
	/*padding: 2em;*/
	/*height: 70vh;*/
	background-color: var(--primary-colour-transparent);
	border-radius: 3em;
	height: fit-content;
}

@keyframes highlight {
  0% {  background-color: rgba(255, 255, 255, 0.0); }
  100% {  background-color: rgba(255, 255, 255, 0.3); }
}
@keyframes highlight-arrow {
  0% { opacity: 1;}
  100% { opacity: 0.6;}
}

.right-panel {
	border-top-right-radius: 3em;
	border-bottom-right-radius: 3em;
	flex-direction: column-reverse;
	height: fit-content;
	justify-content: start;

}
.right-panel div{
	border-radius: 3em;
}

.left-panel {
	border-top-left-radius: 3em;
	border-bottom-left-radius: 3em;
	flex-direction: column-reverse;
	height: fit-content;
	justify-content: end;
}

.left-panel div{
	border-radius: 3em;
}


.flex-flow-inherit {
	flex-wrap: nowrap;
}

@media screen and (max-width: 1024px) /*and (min-width: 420px)*/ {


	.top-row > * {
		flex: 0 0 100% !important;
		max-width: 100%;
	}

	.ben-background-container {
		margin: 3vh 0;
	}
	.ben-image {
		margin-left: 0vw;

	}

	.comment-display {
		/*border-left: 0px solid black;*/
		/*border-top: 1px solid rgba(0, 0, 0, 1);*/
		box-shadow: 0px -3px rgb(0, 0, 0, 0.35);
	}

	.left-panel {
		order: 1;
		/*flex-direction: column-reverse;*/
		/*margin: 3vh 0;*/
	}
	.center-panel {
		height: fit-content;
	  margin: 5vh 0;
		/*height: 60vh !important;*/
	}
	.right-panel {
		order: 2;
		/*margin: 3vh 0;*/
	}
	.icons-container {
		height: 25vh;
	}
	.text-bubble {

		flex: 0 0 100% !important;
		max-width: 100%;
		padding: 0;
	}
	.row {
		height: fit-content;
	}


	.flex-flow-inherit {
		flex-direction: row;
		flex-wrap: wrap;
	}


	.right-col {
		flex-direction: column-reverse;
		align-items: center;
	}

	.icons-container > div {
		width: 100%;
		padding: 3vh;
	}

	.icons-container div:nth-child(2) {
		/*padding-top: initial;*/
	}
	.ben-cartoon {
	}

}


@media screen and (max-width: 420px) {
	.ben-background-container {
		margin: 3vh 0;
	}
	.center-panel {
		/*height: 80vh !important;*/
	}
	.icons-container {
		height: 25vh;
		width: 100%;
	}

	.text-bubble {
		flex: 0 0 100% !important;
		max-width: 100%;
	  text-align: center;
	}

	.text-bubble * {
	  padding-left: 0 !important;
		font-size: 5vw;
	}

	.comment-display > div {
		margin-left: 0px;
		margin-bottom: 4px;
	}

	.comment-display ul span {
		font-size: 5vw;
		margin-left: 0px;
	}

	.comment-display ul > li {
		padding: 0.75rem 0px;
	}

	.comment-display ul .col {
		padding: 0.75rem 0px;
	}
}

svg, path {
    pointer-events: none;
}


.hidden {
	display: none;
}

.fade-in {
	display: none;
	opacity: 0;
  animation: fade-in 0.3s ease-in-out 0.15s;
  animation-fill-mode: forwards;
}

.fade-in-delay {
	overflow: hidden;
	visibility: hidden;
	position: absolute;
  animation: fade-in 0.3s ease-in-out 0.15s;
  animation-fill-mode: forwards;
}

.fade-out {
  animation: fade-out 0.3s ease-in-out;
  animation-fill-mode: forwards;
}

.rotate-transition {
  animation: flip-around 0.7s ease-in;

}

.sliding-transition {
  animation: slide 2s ease-in;

}

@keyframes slide {
		0% {
			
		}
		100% {
			display: hidden;
		}
}

@keyframes flip-around {
	10% {
		transform: rotate3d(0, 1, 0, 0deg);
	}
	40% {
		transform: rotate3d(0, 1, 0.1, 90deg);
		
	}
	60% {
		transform: rotate3d(0, 1, -0.1, 90deg);
	}
	90% {
		transform: rotate3d(0, 1, 0, 0deg);
	}
}

@keyframes fade-in {
  0% { opacity: 0;
  		display: none;
  		position: initial;
  	}
  100% {
  	opacity: 1;
  	display: initial;
  	visibility: visible;
  		position: initial;
  }
}

@keyframes fade-out {
  0% {
  	opacity: 1;
  	display: initial;
  	}
  100% {
  	opacity: 0;
  	display: none;
  }
}
